<template>
  <div class="home">
    <div class="header">
      <img alt="Vue logo" src="../assets/logo.svg">
    </div>

    <div v-for="(section, index) in stock" :key="index" class="section">
      <div class="section-header">
        <img class="section-header--logo" alt="Vue logo" src="../assets/monkey.svg">
        <span class="section-header--title">{{ section.sectionName }}</span>
      </div>

      <div class="section-content">
        <ul>
          <li v-for="(drink, index) in section.sectionContent.sort()" :key="index" class="section-content--item">
            {{ drink }}
          </li>
        </ul>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'Home',
  data () {
    return {
      stock: [
        {
          sectionName: 'Softs',
          sectionContent: [
            'Eau Pétillante',
            'Eau Plate',
            'Coca-Cola',
            'Ice Tea Pêche',
            'Orangina',
            'Jus d\'ananas',
            'Jus fruit de la passion',
            'Jus de Goyave',
            'Cocktails fruités sans alcool',
            'Grenadine',
            'Sirop de Cerise',
            'Sirop de Lilas'
          ]
        },
        {
          sectionName: 'Bières',
          sectionContent: [
            'Cornet Zero (0°)',
            'Maes 25 (5.2°)',
            'Trappe Puur (4.5°)',
            'Trappe Quadruple (10°)',
            'Trappe Blonde (6.5°)',
            'Pêcheresse (2.5°)'
          ]
        },
        {
          sectionName: 'Autres',
          sectionContent: [
            'Cidre Ruwet (4.5°)'
          ]
        },
        {
          sectionName: 'Boissons Chaudes',
          sectionContent: [
            'Chocolat Chaud',
            'Café',
            'Thé vert gingembre verveine',
            'Thé vert japonais',
            'Thé oolong',
            'Matcha',
            'Matcha latte',
            'Café frappé',
            'Roiboos pechr chaud ou froid',
            'Camomille',
            'Tilleul',
            'Ortie',
            'Thé vert pomme canelle',
            'Thé noir de noel',
            'Thé noir epices',
            'Wintercure',
            'Puehr',
            'Infusion de Galanga'
          ]
        },
        {
          sectionName: 'Alcools',
          sectionContent: [
            'Aperol Spritz',
            'Cynar',
            'Gold Strike',
            'Amaro Montenegro',
            'Amaretto',
            'Cherry Surprise ?',
            'Certosa (90°)'
          ]
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  .home {
    padding: 0rem .25rem;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 0rem;
  }

  .section {
    .section-header {
      display: flex;
      align-items: center;
      justify-content: center;

      & > * {
        padding: .5rem;
      }

      &--title {
        font-family: 'Dancing Script',serif;
        font-size: 2.5rem;
      }
    }

    .section-content {
      &--item {
        padding-top: .5rem;
        list-style: none;
      }
    }
  }
</style>
